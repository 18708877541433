.react-datepicker {
  font-family: "Work Sans", sans-serif;
  color: #3a2f2e;
  border: 1px solid rgba(58, 47, 46, 0.15);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-family: "TT Norms Pro";
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #3a2f2e;
}

.react-datepicker__day-name {
  font-weight: 500;
}

.react-datepicker__header {
  background-color: #fff2ee;
  background-clip: padding-box;
  border-bottom-color: rgba(58, 47, 46, 0.15);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #fff2ee;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #305ed8;
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1a3e97;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #fff2ee;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: rgba(58, 47, 46, 0.15);
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: rgba(58, 47, 46, 0.15);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: rgba(58, 47, 46, 0.4);
}

.react-datepicker__navigation:hover *::before {
  border-color: rgba(58, 47, 46, 0.7);
}
