@font-face {
  font-family: "TT Norms Pro";
  font-weight: bold;
  src: url("./TT-Norms-Pro-Bold.ttf");
}

@font-face {
  font-family: "Work Sans";
  font-weight: normal;
  src: url("./WorkSans-Regular.ttf");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  src: url("./WorkSans-Medium.ttf");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url("./WorkSans-SemiBold.ttf");
}

@font-face {
  font-family: "Migrate Icon";
  src: url("./Migrate-Icon.ttf");
}
